import * as moment from "moment";
import { extendMoment } from 'moment-range';
import { MonthsTH, ShortMonthsTH } from "src/data/month";

export default class Utils {

    static sum(obj) {
        var sum = 0;
        for (var el in obj) {
            if (obj.hasOwnProperty(el)) {
                sum += parseFloat(obj[el]);
            }
        }
        return sum;
    }

    static kiloConvert(num: number) {
        // if (num >= 1000000000) {
        //     return (num / 1000000000).toString().replace(/\.0$/, '') + 'G';
        // }
        // if (num >= 1000000) {
        //     return (num / 1000000).toString().replace(/\.0$/, '') + 'M';
        // }
        console.log('num', num);
        if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        } else {
            if (!num) return "0.00";
            let str = num.toString(); //If it's not already a String
            if (str.indexOf(".") > -1)
                str = str.slice(0, (str.indexOf(".")) + 3).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"); //With 3 exposing the hundredths place
            else
                str = parseFloat(str).toFixed(1).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

            return str;
        }
    }

    static CfAvgWeekly() {
        return 6480 / 365 * 7;
    }

    static CfAvgMonthly() {
        return 6480 / 365 * 30;
    }

    static CfAvgYearly() {
        return 6480 / 365;
    }

    static ToThaiDate(dateStr, format = 'YYYY-MM-DD') {

        let momentjs: any = moment;
        const date = momentjs(dateStr, format);
        const month = date.format('M');
        const year = date.format('YYYY') < 2500 ? date.add(543, 'y').format('YYYY') : date.format('YYYY');

        return date.format('D') + ' ' + MonthsTH[month - 1] + ' ' + year;
    }

    static ToShortThaiDate(dateStr, format = 'YYYY-MM-DD') {

        let momentjs: any = moment;
        const date = momentjs(dateStr, format);
        const month = date.format('M');
        const year = date.format('YYYY') < 2500 ? date.add(543, 'y').format('YY') : date.format('YY');

        return date.format('D') + ' ' + ShortMonthsTH[month - 1] + ' ' + year;
    }

    static ToThaiDateRange(begin, end) {

        const year = end.format('YYYY') < 2500 ? end.add(543, 'y').format('YYYY') : end.format('YYYY');
        return begin.format('D') + '-' + end.format('D') + ' ' + MonthsTH[end.format('M') - 1] + ' ' + year;

        // let momentjs: any = moment;
        // const date = momentjs(dateStr, format);
        // const month = date.format('M');
        // const year = date.format('YYYY') < 2500 ? date.add(543, 'y').format('YYYY') : date.format('YYYY');

        // return date.format('D') + ' ' + MonthsTH[month-1] + ' ' + year;
    }

    static WeekRangeByMonth(month) {

        // let momentjs: any = moment;
        const date = moment(`${month + 1}`, 'M');

        const weeks = this.getDaysInAMonth(date.year(), date.month());
        // console.log('weeks', weeks);
        return weeks;
    }

    static WeekRangeByWeekNumber(date) {
        const momentExtended = extendMoment(moment);
        const firstWeekDay = date.startOf('isoWeek');
        const lastWeekDay = date.endOf('isoWeek');
        const weekRange = momentExtended.range(firstWeekDay, lastWeekDay);
        return weekRange;
    }

    static WeekToThaiDateRange(week) {
        const firstDay = week[0];
        const lastDay = week[week.length - 1];
        const firstDayTH = this.ToShortThaiDate(firstDay.format('YYYY-MM-DD'));
        const lastDayTH = this.ToShortThaiDate(lastDay.format('YYYY-MM-DD'));
        return firstDayTH == lastDayTH ? firstDayTH : firstDayTH + ' - ' + lastDayTH;
    }

    static getDaysInAMonth = (year, month) => {
        const momentExtended = extendMoment(moment);
        const startDate = momentExtended([year, month]);

        const firstDay = momentExtended(startDate).startOf('month')
        const endDay = momentExtended(startDate).endOf('month')

        const monthRange = momentExtended.range(firstDay, endDay)
        const weekNumbers = [];
        const days = Array.from(monthRange.by('day'));
        days.forEach(it => {
            if (!weekNumbers.includes(it.week())) {
                weekNumbers.push(it.week());
            }
        });

        // console.log('weekNumbers', weekNumbers);

        const calendar = []
        weekNumbers.forEach(weekNumber => {
            // console.log('isoWeek', momentExtended([year, month]).week(weekNumber).startOf('isoWeek'));

            const firstWeekDay = momentExtended([year, month]).week(weekNumber).startOf('isoWeek');
            const lastWeekDay = momentExtended([year, month]).week(weekNumber).endOf('isoWeek');
            const weekRange = momentExtended.range(firstWeekDay, lastWeekDay);

            let weekDays = Array.from(weekRange.by('day'));
            weekDays = weekDays.filter(d => d.month() == month);

            calendar.push(weekDays);
        })

        return calendar;
    }

    static stripeEmoji = (text) => {
        const withEmojis = /\p{Emoji}/u;
        text = text.replaceAll(/\p{Emoji}/ug, '');
        return text;
    }

}