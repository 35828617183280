// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: "fakebackend",
  appUrl: 'https://www-staging.loveyounature.org',
  apiUrl: "https://api-staging.loveyounature.org",
  // apiUrl: "https://62ea-2001-fb1-43-2c68-e160-41ed-e9a7-4e2a.ap.ngrok.io",
  shareUrl: "https://lyn-share.web.app",

  liffLyn: "1657341442-rv0lx1Wz",
  liffSharePopup: "1657341442-ro0d8O7P",
  missionUrl: "https://liff.line.me",
  lineOAUrl: "https://lin.ee/W14bzho",

  ecolifeToken: "d92033d9a08dadab0793c1e9cbc443e47361b800 a7132ad5cdce6068932e43f9ab2ea5ac",

  firebaseConfig: {
    apiKey: "AIzaSyBT6DNIV9QlrKyLnUPqp5uqwH3rNFV4olg",
    domainPrefix: "lyndev.page.link",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
};
