import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Customer } from "../shared/models/customer.model";
import { CustomerCfc } from "../shared/models/customercfc.model";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  // Node/Express API
  REST_API: string = environment.apiUrl + "/customer";
  token;

  constructor(private httpClient: HttpClient) { }

  // Ecolife
  ecolife(data: any): Observable<any> {
    let API_URL = `https://ioscan.ecolifeapp.com`;
    return this.httpClient
      .post(API_URL, data)
      .pipe();
  }

  // Add
  addCustomer(data: Customer): Observable<any> {
    let API_URL = `${this.REST_API}`;

    return this.httpClient
      .post(API_URL, data)
      .pipe();
  }

  // Add
  getMarriedImage(data: any): Observable<any> {
    let API_URL = `${environment.apiUrl}/image/marriedImage`;

    return this.httpClient
      .post(API_URL, data)
      .pipe();
  }

  // Get Single Object
  getCustomer(id: any): Observable<any> {
    let API_URL = `${this.REST_API}/${id}`;

    return this.httpClient.get(API_URL).pipe(
      map((res: any) => {
        return res || {};
      }),

    );
  }

  // Get Single Object
  findByLineUserId(id: any): Observable<any> {
    let API_URL = `${this.REST_API}/find/findByLineUserId?lineUserId=${id}`;

    return this.httpClient.get(API_URL).pipe(
      map((res: any) => {
        return res || null;
      }),

    );
  }

  // Update
  updateCustomer(cusId: string, data: any): Observable<any> {
    let API_URL = `${this.REST_API}/${cusId}`;
    return this.httpClient.patch(API_URL, data);
  }

  // Get customer's CFC
  getCustomerCfc(custId: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/cfcActivity`;
    return this.httpClient.get(API_URL).pipe(
      map((res: any) => {
        return res || null;
      }));
  }

  getCustomerCfcSummary(custId: any): Promise<any> {
    let applianceCfc = 0;
    let foodCfc = 0;
    let transportCfc = 0;
    let officeCfc = 0;
    let travelCfc = 0;

    return new Promise<{}>((resolve, reject) => {
      this
        .getCustomerCfc(custId)
        .subscribe((res) => {

          console.log('getCustomerCfcSummary', res)

          for (let i = 0; i < res.appliance.length; i++) {
            let cfc = res.appliance[i].cfValue * parseFloat(res.appliance[i].cfConst);
            applianceCfc += cfc;
          }

          // Calculate food's CFC
          for (let i = 0; i < res.food.length; i++) {
            let cfc = res.food[i].cfValue * parseFloat(res.food[i].cfConst);
            foodCfc += cfc;
          }

          // Calculate transport's CFC
          for (let i = 0; i < res.transport.length; i++) {
            let cfc = res.transport[i].cfValue * parseFloat(res.transport[i].cfConst);
            transportCfc += cfc;
          }

          // Calculate office's CFC
          for (let i = 0; i < res.office.length; i++) {
            let cfc = res.office[i].cfValue * parseFloat(res.office[i].cfConst);
            officeCfc += cfc;
          }

          // Calculate travel's CFC
          for (let i = 0; i < res.travel.length; i++) {
            let cfc = res.travel[i].cfValue * parseFloat(res.travel[i].cfConst);
            travelCfc += cfc;
          }

          const sum = {
            applianceCfc: applianceCfc,
            foodCfc: foodCfc,
            transportCfc: transportCfc,
            officeCfc: officeCfc,
            travelCfc: travelCfc
          }
          
          console.log('sum', sum);

          resolve(sum);

        });
    });

  }

  // Get customer's CFC
  getCustomerCfcById(custId: any, id: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/cfcActivity/${id}`;

    return this.httpClient.get(API_URL)
      .pipe(
        map((res: any) => {
          return res || {};
        })
      );
  }

  // Add customer's CFC
  addCustomerCfc(custId: any, data: CustomerCfc): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/cfcActivity`;

    return this.httpClient
      .post(API_URL, data)
      .pipe();
  }

  //Update customer's CFC
  updateCustomerCfc(custId: any, data: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/cfcActivity`;

    return this.httpClient
      .patch(API_URL, data)
      .pipe();
  }

  // get daily cfc
  getDailyCFC(custId: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/dailyCFC`;

    return this.httpClient
      .get(API_URL)
      .pipe(
        map((res: any) => {
          return res || {};
        }),

      );
  }

  // add daily cfc
  addDailyCFC(custId: any, data: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/dailyCFC`;

    return this.httpClient
      .post(API_URL, data)
      .pipe();
  }

  // chart
  barChartWeekly(custId: any, startDate: any, endDate: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/chart/barChartWeekly?startDate=${startDate}&endDate=${endDate}`;

    return this.httpClient.get(API_URL)
      .pipe(
        map((res: any) => {
          return res || {};
        }),

      );
  }

  // chart
  barChartWeeklyByCateogory(custId: any, startDate: any, endDate: any, category: string): Observable<any> {
    console.log('barChartMontlyByCategory', category);
    let API_URL = `${this.REST_API}/${custId}/chart/barChartWeekly?startDate=${startDate}&endDate=${endDate}&category=${category}`;
    return this.httpClient.get(API_URL)
      .pipe(
        map((res: any) => {
          return res || {};
        }),

      );
  }

  barChartMontly(custId: any, month: any, year: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/chart/barChartMontly?month=${month}&year=${year}`;

    return this.httpClient.get(API_URL)
      .pipe(
        map((res: any) => {
          return res || {};
        }),

      );
  }

  barChartMontlyByCategory(custId: any, month: any, year: any, category: string): Observable<any> {
    console.log('barChartMontlyByCategory', category);
    let API_URL = `${this.REST_API}/${custId}/chart/barChartMontly?month=${month}&year=${year}&category=${category}`;

    return this.httpClient.get(API_URL)
      .pipe(
        map((res: any) => {
          return res || {};
        }),

      );
  }

  lineYearlyChart(custId: any, year: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/chart/lineChartYearly?year=${year}`;

    return this.httpClient.get(API_URL).pipe(
      map((res: any) => {
        return res || {};
      }),

    );
  }

  // Donut chart
  donutChart(custId: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/chart/donut`;

    return this.httpClient.get(API_URL).pipe(
      map((res: any) => {
        return res || {};
      }),

    );
  }

  // upload image
  uploadImage(data: any): Observable<any> {
    let API_URL = `${environment.apiUrl}/image/upload`;

    return this.httpClient
      .post(API_URL, data)
      .pipe();
  }

  // take mission
  missionTaken(customerId: any, specialMissionId: any): Observable<any> {
    let API_URL = `${this.REST_API}/${customerId}/missionsTaken`;

    return this.httpClient
      .post(API_URL, { missionId: specialMissionId })
      .pipe();
  }

  setCfActivity(value) {
    localStorage.setItem('@lyn_cf_activities', JSON.stringify(value));
  }

  getCfActivity() {
    return JSON.parse(localStorage.getItem('@lyn_cf_activities'));
  }

  removeCfActivity() {
    localStorage.removeItem('@lyn_cf_activities');
  }
}
