export class Customer {
  customerId: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
  birthDateDisplay: string;
  email: string;
  tel: string;
  referCode: string;
  lover: string;
  createdAt: Date;
  registeredAt: Date;
  status: string;
  isVerify: boolean;
  lastLogin: Date;
  profileImage: string;
  loverImage: string;
  marriedImage: string;
  lineUserId: string;
  isAcceptedPDPA: boolean;
  notify: boolean = false;
  rccPoint: any = 0;
  totalCfc: any = 0;
}
