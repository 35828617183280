import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from '../service/account.service';
import { Router } from '@angular/router';
import liff from '@line/liff/dist/lib';
import { environment } from 'src/environments/environment';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private accountService: AccountService,
        private router: Router,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.get("skip"))
            return next.handle(request);

        return next.handle(request).pipe(catchError(err => {

            if ([401, 403].includes(err.status)) {
                // auto logout if 401 or 403 response returned from api
                // this.accountService.logout();
                // this.router.navigateByUrl('/register');
                alert('authentication failed!');

                this.accountService
                    .verifyIdToken()
                    .subscribe(res => {
                        console.log('verifyIdToken', res)
                        // liff.logout();
                    }, (err) => {
                        if (err.error && err.error.error_description === 'IdToken expired.') {
                            liff.logout();
                            console.log('liff has been logout');
                            window.location.reload();
                        }
                    });
                // console.log('url', window.location.href);
                // console.log('request', request);
                // console.log('next', next);


                // liff.init({ liffId: environment.liffLyn });

                // if (liff.isLoggedIn()) {
                //     console.log('line still logged in!');
                //     const idToken = liff.getIDToken();
                //     const decoded = liff.getDecodedIDToken();

                //     console.log('id_token', idToken);
                //     console.log('client_id', decoded.aud);
                // } else {
                //     liff.login({ redirectUri: window.location.href });
                // }

                // window.location.href = 'errors/unautho';
            }

            if ([500].includes(err.status)) {
                const error = err.error?.message || err.statusText;
                alert(error);
            }

            const error = err.error?.message || err.statusText;
            console.error(err);
            return throwError(error);
        }))
    }
}